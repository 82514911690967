import React, { lazy, Suspense, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import { AuthProvider } from "./components/AuthContext";
import { useLocation } from "react-router-dom";
import Loader from "./components/Shared/Loader";
import { useLoader } from "./components/Shared/UseLoader";

const ManageJobsGrid = lazy(() => import("./components/Pages/ManageJobsGrid"));
const ManageGovtSchemes = lazy(
  () => import("./components/Pages/ManageGovtSchemes")
);
const GovtSchemesGrid = lazy(
  () => import("./components/Pages/GovtSchemesGrid")
);
const ManageAdmissions = lazy(
  () => import("./components/Pages/ManageAdmissions")
);
const ManageAdmissionGrid = lazy(
  () => import("./components/Pages/ManageAdmissionGrid")
);
const ManageJobs = lazy(() => import("./components/Pages/ManageJobs"));
const HomePage = lazy(() => import("./components/Pages/Home"));
const Login = lazy(() => import("./components/Auth/SignIn"));
const Register = lazy(() => import("./components/Auth/SignUp"));
const NotificationList = lazy(
  () => import("./components/Pages/NotificationList")
);
const ErrorPage = lazy(() => import("./components/Shared/Error404"));
const NotificationDetailsPage = lazy(
  () => import("./components/Pages/NotificationDetailsPage")
);

function useShouldShowLayout() {
  const location = useLocation();
  const pathsWithoutLayout = ["/Login", "/Register"];
  return !pathsWithoutLayout.includes(location.pathname);
}

function App() {
  const shouldShowLayout = useShouldShowLayout();
  const loading = useLoader();
  // const getToken = useCallback(async () => {
  //   //const newToken = await fetchToken();
  //   //localStorage.setItem("token", newToken);
  // }, []);

  // if (!localStorage.getItem("token")) {
  //   return <Navigate to="/Login" replace />;
  // }

  const publicRoutes = (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" Component={ErrorPage} />
        <Route path="/Login" Component={Login} />
        <Route path="/Register" Component={Register} />
        <Route path="/Home" Component={HomePage} />
        <Route path="/jobs" Component={NotificationList} />
        <Route path="/admissions" Component={NotificationList} />
        <Route path="/others" Component={NotificationList} />
        <Route path="/jobDetails" Component={NotificationDetailsPage} />
        <Route path="/AdmissionDetails" Component={NotificationDetailsPage} />
        <Route path="/otherDetails" Component={NotificationDetailsPage} />
        <Route path="/manageJobs" Component={ManageJobs} />
        <Route path="/manageJobs/:jobId" Component={ManageJobs} />
        <Route path="/manageJobsGrid" Component={ManageJobsGrid} />
        <Route path="/manageAdmissions" Component={ManageAdmissions} />
        <Route path="/manageAdmissions/:admId" Component={ManageAdmissions} />
        <Route path="/manageAdmissionsGrid" Component={ManageAdmissionGrid} />
        <Route path="/manageGovtSchemes" Component={ManageGovtSchemes} />
        <Route
          path="/manageGovtSchemes/:schemeId"
          Component={ManageGovtSchemes}
        />
        <Route path="/manageGovtSchemesGrid" Component={GovtSchemesGrid} />
        <Route path="/" element={<Navigate to="/Home" replace />} />
      </Routes>
    </Suspense>
  );
  return (
    <AuthProvider>
      {loading && <Loader />}
      {shouldShowLayout ? <Layout>{publicRoutes}</Layout> : publicRoutes}
    </AuthProvider>
  );
}

export default App;
